export default {
    name: 'radioTree',
    data () {
        return {}
    },
    props: {
        model: {
            type: Object,
            default: () => {}
        },
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        handlerExpand(val) {
            this.$emit('handlerExpand', val)
        },
        toSelectRadio(val) {
            this.$emit('toSelectRadio', val)
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/